<template>
    <div class="hot">
        <Header></Header>
        <div class="hot-content">
            <div class="one-item">
                <div class="item-list" v-for="item in itemList" :key="item.id" 
                @click="goDetails(item.id)">
                    <div>
                        <span>{{item.title}}</span>
                        <div>
                            <span>时间：{{item.createtime}}</span>
                        </div>
                    </div>
                    <img :src="BaseUrl + item.image" alt="">
                </div>
            </div>
            <div class="downPage">
                  <el-pagination
                      background
                      prev-text="上一页"
                      next-text="下一页"
                      @prev-click="pagePrev"
                      @next-click="pageNext"
                      @current-change="pageChange"
                      page-size="9"
                      layout=" prev, pager, next">
                </el-pagination>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '../../../components/mobile/Header.vue'
import Footer from '../../../components/mobile/Footer.vue'

export default {
    name:'Hot',
    data(){
        return{
           page:1,
           pageSize:'10',
           totalPage:'',
           itemList:[]
        }
    },
    created(){
        this.getData()
    },
    methods:{
        getData(){
            this.$http({
                url:'/anli/list',
                params:{
                    page:this.page,
                    pagesize:this.pageSize
                }
            }).then(res=>{
                console.log(res)
                this.totalPage =res.data.total
                this.itemList = res.data.list
            })
        },
        goDetails(id){
            this.$router.push(`/m_figuredetail/${id}`)
        },
          // 上一页
        pagePrev(){
            this.page--
            this.getData(this.page)
        },
        // 下一页
        pageNext(){
             console.log('下一页')
             this.page++
             this.getData(this.page)
        },
        // 页面改变
        pageChange(e){
            console.log(e)
            this.page = e
            this.getData(this.page)
        },
    },
    components:{
      Header,
      Footer
   }
}
</script>

<style lang='less' scoped>
/deep/.el-carousel__container{
    height: 362.26px!important;
}
/deep/.el-carousel__indicator--horizontal{
    display: none!important;
}
/deep/.el-carousel__arrow--right,
/deep/.el-carousel__arrow--left{
    display: none;
}
/deep/.el-pagination button, .el-pagination span:not([class*=suffix]){
    width: 100px!important;
    font-size: 20px!important;
}
.hot{
    width: 750px;
    .hot-content{
        width: 750px;
        margin:30px 0 70px 0;
        .one-item{
           .item-list{
               display: flex;
               align-items: center;
               width: 690px;
               margin: 0 auto 19px;
               padding-bottom: 20px;
               border-bottom: 1px solid #E5E5E5;
               >div:nth-child(1){
                   margin-right: 25px;
                   width: 470px;
                   >span:nth-child(1){
                       font-size: 28px;
                       color: #333333;
                       text-overflow: -o-ellipsis-lastline;
                       overflow: hidden;
                       text-overflow: ellipsis;
                       display: -webkit-box;
                       -webkit-line-clamp: 2;
                       line-clamp: 2;
                       -webkit-box-orient: vertical;
                   }
                   >div:nth-child(2){
                   
                       margin-top: 10px;
                       font-size: 20px;
                       color: #666666;
                       >span:nth-child(1){
                           display: block;
                           width: 235px;
                           margin-right: 15px;
                       }
                   }

               }
               >img:nth-child(2){
                   width: 186px;
                   height: 110px;
               }
           }
        }
        .downPage{
            width: 650px!important;
            margin: 50px auto 0;
            text-align: center;
        }
        /deep/.el-pagination{
            width: 650px!important;
        }
     
    } 

}
</style>